export const products = [
    {
        "image": "/img/watchnewCOPY.png",
        "id": "1492",
        "name": "Часы AMST",
        "description": "Часы AMST - олицетворение статуса и успеха, чувства стиля и вкуса, оригинальности и самодостаточности. Это нечто большее, чем просто часы, это шедевры высокого часового искусства. Часы AMST на сегодняшний день являются показателем успеха современного мужчины. Звёзды Голливуда, шоу-бизнеса, политики и спортсмены не могут ошибаться. \"Каждый уважающий себя мужчина должен носить эти Часы!\"",
        "price": 49.99
    },
    {
        "image": "/img/2.jpg",
        "id": "1493",
        "name": "Smart Watch V8",
        "description": "Фитнес-трекер, камера, телефон, смарт часы на твоей руке. Классические и одновременно спортивные. Показывают время по взмаху руки и могут звонить! Они предлагают вам важную информацию там, где она нужна. Позволяют связаться с близкими людьми или проконтролировать важные для вас вещи. И при этом показывают точное время. Да, это часы. Но совсем не такие, какими вы их всегда себе представляли.",
        "price": 49.99
    },
    {
        "image": "/img/headerImage.jpg",
        "id": "1494",
        "name": "Diesel Only The Brave",
        "fit": "cover",
        "description": "Эти часы - не просто аксессуар, а заявление о себе. Они говорят о вашем стиле, о том, что вы цените качество и индивидуальность. Они не просто показывают время, они рассказывают историю. История вашего стиля, вашего вкуса, вашего стремления к совершенству. В коллекции представлены модели на любой вкус: от классических черных до ярких золотых. Каждая модель - это уникальный дизайн, который подчеркнет вашу индивидуальность. Эти часы - это не просто аксессуар, это часть вашего образа. Они дополнят ваш костюм, подчеркнут ваш образ, станут вашим верным спутником в любых ситуациях.",
        "price": 89.99
    },
    {
        "image": "/img/headerImage (1).jpg",
        "id": "1495",
        "name": "Часы Lacoste",
        "fit": "cover",
        "description": "Часы Lacoste – это не просто аксессуар, это заявление о стиле. Силиконовый ремешок, мягкий и приятный на ощупь, идеально облегает запястье, создавая ощущение комфорта. Универсальный дизайн делает их подходящими как для мужчин, так и для женщин, а стильный внешний вид позволяет им гармонично вписаться в любой образ. Диаметр циферблата 40 мм – идеальный размер для комфортного чтения времени, а длина ремешка 24 см обеспечивает идеальную посадку на запястье. Lacoste – это часы, которые подчеркнут вашу индивидуальность и добавят изысканности в ваш образ.",
        "price": 44.99
    },
    {
        "image": "/img/headerImage (2).jpg",
        "id": "1496",
        "name": "Часы Casio F-91W",
        "fit": "cover",
        "description": "Это не просто часы, это символ простоты и надежности. Это классика, которая неподвластна времени, как и сам дизайн. Сдержанный, лаконичный, с минимальным количеством деталей, он идеально вписывается в любой образ, будь то спортивный костюм или деловой костюм. Это часы для тех, кто ценит функциональность и практичность, для тех, кто не гонится за модой, а выбирает качество и надежность. Это часы, которые всегда будут с вами. Они выдержат любые испытания, будь то жара, холод, влажность или удары. Их прочный корпус и минеральное стекло защитят их от повреждений, а точный кварцевый механизм обеспечит точность хода. Это часы, которые всегда будут вам служить верой и правдой, как верный друг.",
        "price": 39.99
    },
    {
        "image": "/img/2 (1).jpg",
        "id": "1497",
        "name": "Часы Smart Watch",
        "fit": "cover",
        "description": "Фитнес-трекер, камера, телефон, смарт часы на твоей руке. Классические и одновременно спортивные. Показывают время по взмаху руки! Они предлагают вам важную информацию там, где она нужна. Тогда, когда она вам нужна. И помогают выполнять повседневные задачи. Позволяют связаться с близкими людьми или проконтролировать важные для вас вещи. И при этом показывают точное время. Да, это часы. Но совсем не такие, какими вы их всегда себе представляли.",
        "price": 39.99
    },
    {
        "image": "/img/2 (2).jpg",
        "id": "1498",
        "name": "Смарт часы BAND-LUX",
        "description": "Универсальные часы, подходят мужчинам, женщинам и даже детям. Просты в использовании и нет сложных алгоритмов переключений функций. Часы поставляются с браслетом, зарядным устройством, с упаковкой, инструкцией с QR кодом для скачивания русскоязычного приложения это очень удобно. Электронное устройства SmartWatch помогают в жизни в сочетании с телефоном.С умными часами вы сможете контролировать кардиоситему, пульс сердца отображается при запросе на экране. Также с часами в комплекте поставляется очень удобная миниатюрная зарядка, которую удобно брать с собой в путеществие. Также очень удобно, что часы не надо заряжать каждый день, как например смарт часы известной американской марки, плюс к этому очень быстрая зарядка. Когда на ваш телефон приходит сообщение на телефон, то сигнал света и вибрации откликается в часах, помогает быстро реагировать и отвечать на отправленные письма. Силиконовый ремешок позволяет его мыть и содержать в чистоте, что очень хорошо для гигиены рук.",
        "price": 69.99
    },
    {
        "image": "/img/4.jpg",
        "id": "1499",
        "fit": "cover",
        "name": "Часы CASIO G-SHOCK",
        "description": "Часы наручные мужские Casio на спортивном силиконовом, металлическом браслете с круглым корпусом. Электронные цифры показывают дни недели, 24 часовой формат времени, месяц и дату. Функция будильника, секундомера и подсветки циферблата делают часы незаменимыми. Данные часы являются влогозащищёнными (защита от брызг и от дождя), но не являются водонепроницаемыми. Противоударное стекло - всё это делает их еще более надежными. Кварцевый механизм прослужит долгие годы. В отличии от механических часов с автоподзаводом, часы на батарейках не требуют внимания.",
        "price": 59.99
    },
    {
        "image": "/img/description_image2.jpg",
        "id": "1500",
        "fit": "cover",
        "name": "Часы Starry Sky Watch",
        "description": "Часы с браслетами Starry Sky Watch - современная разработка для утонченных ценительниц качественного дизайна. Минеральная обработка корпуса придает комплекту кристальный оттеночный блеск. Вариации цвета идентичны с драгоценными камнями высшей пробы. Высокоточный кварцевый механизм внутри идеально проработанного корпуса.",
        "price": 39.99
    },
    {
        "image": "/img/HTB1No7.RpXXXXXmXpXXq6xXFXXXa.jpg_.webp",
        "id": "1501",
        "fit": "cover",
        "name": "Часы Lighter Watch",
        "description": "Это не просто наручные часы! Это не просто зажигалка! Это 2в1 НАРУЧНЫЕ ЧАСЫ С ЗАЖИГАЛКОЙ USB. Таким подарком вы точно удивите любимого мужчину, мужа, друга. Наручные часы станут хорошим подарком для руководителя, шефа или босса. Электрозажигалка не требует заправки газом или бензином. Для зарядки достаточно подключиться к любому юсб устройству или розетке. Полностью безопасносна для детей и животных, время работы 12-15 секунд, а после самостоятельно отключается. Электронная зажигалка доставляется в плотной, подарочной коробке. Это уже готовый подарок для мужчины на любой праздник.",
        "price": 69.99
    },
    {
        "image": "/img/2.webp",
        "id": "1502",
        "fit": "cover",
        "name": "Часы наручные SKMEI",
        "description": "Часы наручные SKMEI с вращающимся колесом и суппортом 🔝 Качественная luxe модель для авто-любителей с надежным кварцевым механизмом. Вращающийся диск Rays Volk Racing TE37 изображает автомобильное колесо вместе с суппортом 🏎️ Диск вращается при движении, что придаёт часам индивидуальность и внимание со стороны.",
        "price": 69.99
    },
    {
        "image": "/img/Снимок экрана 2024-09-04 в 18.01.09.png",
        "id": "1503",
        "name": "Smart Baby Watch Y79",
        "description": "Smart Baby Watch — были придуманы и разработаны инженерами с детьми и семьями, у которых были одинаковые проблемы. Связь с ребёнком трудно поддерживать. Звонок телефона легко не заметить, а сам смартфон забыть или поетрять. Решением всех проблем стала разработка умных часов!",
        "price": 49.99
    },
    {
        "image": "/img/3.jpg",
        "id": "1504",
        "fit": "cover",
        "name": "Наручные часы с зажигалкой",
        "description": "Стильные наручные часы с зажигалкой внутри удивят и порадуют любого мужчину и станут оригинальным подарком. Часы отличаются изящным внешним видом и практичной функцией. Зажигалка в часах не требует заправки газом или бензином. Она не гаснет на ветру. Для еë зарядки достаточно подключиться к любому usb устройству. Поставляются в подарочной коробке.",
        "price": 69.99
    },
    {
        "image": "/img/watch_gold.jpg",
        "id": "1505",
        "name": "Часы с крутящимся циферблатом",
        "description": "Эти часы могут стать отличным аксессуаром, и украсят любую женскую руку. Ведь часы с вращающимися циферблатами — это новое дизайнерское решение и настоящий тренд этого сезона!",
        "price": 59
    },
    {
        "image": "/img/51PAcS5HFcL._AC_UF1000,1000_QL80_.jpg",
        "id": "1506",
        "name": "Умные часы X5 Pro Max",
        "description": "Лучшая функциональность, продуманный интерфейс на русском языке и безупречное качество сделали умные смарт-часы про X5 Max с фитнес трекером замечательным выбором. В них воплощено действительно все для комфортного пользования: большой выбор циферблатов, удобное управление музыкой, календарь, и многое другое. Приложение HD Fit Pro доступно для iOS и Android. Синхронизация со смартфоном происходит посредством блютуз. Стильный смарт браслет в ударопрочном металлическом корпусе оснащен высокочувствительным сенсором и красочным экраном. Оцените и Вы, насколько комфортно пользоваться 1,39 дюймовым ярким дисплеем с понятным интерфейсом.",
        "price": 129.99
    },
    {
        "image": "/img/1.webp",
        "id": "1507",
        "name": "Умные часы HW 6Max",
        "description": "Умные часы Premium 6Max являются новинкой 2024 года. И по праву могут считаться самыми технологичными и универсальными смарт часами на сегодняшний день. А так же компас, игры, измерение температуры тела. Функции, которые помогут отслеживать вашу физическую активность, количество шагов, потраченные калории и даже качество сна. Спортивное устройство оснащено защитой от попадания пыли и пота, дождя и брызг. С помощью этого стильного гаджета вы сможете принимать и отвечать на звонки, благодаря встроенному микрофону. Используя его, вы сможете управлять камерой и приложением для музыки.",
        "price": 149.99
    },
    {
        "image": "/img/474809-b3c8911235da03b0e6610cf2a21bdefb.webp",
        "id": "1508",
        "fit": "cover",
        "name": "Умные часы X2 PLUS",
        "description": "Круглые умные часы X2 PLUS предлагают сменные корпуса и ремешки в черном и белом цветах. Они станут идеальным помощником для активного и здорового образа жизни как для женщин, так и для мужчин. Эти часы служат стильным аксессуаром и полезным гаджетом, подключающимся к устройствам на ОС Android и iOS. Они могут записывать данные без телефона, передавая их при повторной синхронизации. В комплекте идет дополнительный сменный корпус и ремешок, позволяя менять внешний вид под настроение и стиль. Элегантный дизайн, расширенные функции и длительное время автономной работы до 5 дней делают эти часы идеальным компаньоном для достижения целей в фитнесе.",
        "price": 139.99
    },
    {
        "image": "/img/headerImage (3).jpg",
        "id": "1509",
        "name": "Умные часы GS FENIX",
        "description": "Эти невероятно прочные часы, выполненные в корпусе из усиленного волокнами полимера, оснащены ярким дисплеем диагональю 1,5 дюйма. Стильное кольцо из нержавеющей стали вокруг циферблата придает им современный и элегантный вид. Часы прошли испытания по американским военным стандартам на устойчивость к температурным и ударным воздействиям, а также обладают высокой степенью водонепроницаемости. Надежный кнопочный интерфейс, способный работать в самых экстремальных условиях, сочетается с чувствительным сенсорным экраном, обеспечивая быстрый доступ к разнообразным опциям и функциям.",
        "price": 109.99
    },
];
