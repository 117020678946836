import { Box, Button, Card, Link, Stack, Typography, Modal, ModalDialog, ModalClose, DialogContent, DialogTitle, Input } from '@mui/joy';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import OrderService from '../OrderService';
import { useNavigate } from "react-router-dom";

export default function ProductCard({ product }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [isDesc, setIsDesc] = useState(false);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setOpen(false);
        await OrderService.createOrder(phone, name, product.id, product.price);
        navigate("/thank_you");
    };

    return (
        <>
            <Card sx={{ display: 'inline-block', p: 2, backgroundColor: 'white' }} variant='plain'>
                <Stack spacing={1}>
                    <img src={product.image} style={{ width: '300px', height: '200px', objectFit: product.fit ? product.fit : 'scale-down', borderRadius: '8px' }} />
                    <Typography level='title-lg'>{product.name}</Typography>
                    <Typography level='body-lg'><span style={{ textDecoration: 'line-through' }}>{product.price * 2} BYN</span> <span style={{ color: 'red', fontWeight: 500 }}>{product.price} BYN</span></Typography>
                    <Box sx={{ width: '100%', marginX: 10, display: 'flex' }}>
                        <Link onClick={() => { setOpen(true); setIsDesc(true); }} underline='none' sx={{ width: '100%' }}>Подробнее</Link>
                        <Button onClick={() => { setOpen(true); setIsDesc(false); }} sx={{ width: '80%' }} variant='outlined'>Купить</Button>
                    </Box>
                </Stack>
            </Card>

            <Transition in={open} timeout={400}>
                {(state) => (
                    <Modal
                        keepMounted
                        open={!['exited', 'exiting'].includes(state)}
                        onClose={() => setOpen(false)}
                        slotProps={{
                            backdrop: {
                                sx: {
                                    opacity: 0,
                                    backdropFilter: 'none',
                                    transition: `opacity 400ms, backdrop-filter 400ms`,
                                    ...{
                                        entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                        entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                                    }[state],
                                },
                            },
                        }}
                        sx={{
                            visibility: state === 'exited' ? 'hidden' : 'visible',
                        }}
                    >
                        <ModalDialog
                            sx={{
                                opacity: 0,
                                transition: `opacity 300ms`,
                                ...{
                                    entering: { opacity: 1 },
                                    entered: { opacity: 1 },
                                }[state],
                            }}
                        >
                            <ModalClose />
                            <DialogTitle>{isDesc ? product.name : "Заполните форму"}</DialogTitle>
                            <DialogContent sx={{ pt: 2 }}>
                                {isDesc ? (
                                    <Typography>{product.description}</Typography>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <Stack spacing={2}>
                                            <Input
                                                placeholder='Ваше имя...'
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                            <Input
                                                placeholder='Ваш номер телефона...'
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                            />
                                            <Button type='submit' variant='outlined'>
                                                Заказать
                                            </Button>
                                        </Stack>
                                    </form>
                                )}
                            </DialogContent>
                        </ModalDialog>
                    </Modal>
                )}
            </Transition>
        </>
    )
}