import MenuIcon from '@mui/icons-material/Menu';
import { Typography, Button, IconButton, Box, Stack, Link, Drawer, List, ModalClose } from '@mui/joy'
import { useTheme, useMediaQuery } from '@mui/material';
import { useState } from "react";

function scrollToElementById(id) {
    const element = document.getElementById(id);
    if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offset = 10;
        const scrollPosition = window.scrollY + elementPosition - offset;

        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }
}

export default function Header() {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    backgroundColor: 'white',
                    p: isMobile ? 2 : 2.5,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                    zIndex: 1000
                }}
            >
                {isMobile ? (
                    <IconButton onClick={() => setOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                ) : (
                    <Stack direction='row' spacing={6} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <Link onClick={() => scrollToElementById("catalog")} underline="none">Каталог</Link>
                        <Link onClick={() => scrollToElementById("price")} underline="none">Почему так дешево</Link>
                        <Link onClick={() => scrollToElementById("delivery")} underline="none">Доставка и оплата</Link>
                        <Link onClick={() => scrollToElementById("reviews")} underline="none">Отзывы</Link>
                    </Stack>
                )}
            </Box >

            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                variant="plain"
                color="neutral"
                sx={{
                    '--Drawer-transitionDuration': open ? '0.4s' : '0.2s',
                    '--Drawer-transitionFunction': open
                        ? 'cubic-bezier(0.79,0.14,0.15,0.86)'
                        : 'cubic-bezier(0.77,0,0.18,1)',
                }}
            >
                <ModalClose />
                <Box role="presentation" sx={{ p: 2 }}>
                    <Stack spacing={1}>
                        <Link onClick={() => { scrollToElementById("catalog"); setOpen(false); }} underline="none">Каталог</Link>
                        <Link onClick={() => { scrollToElementById("price"); setOpen(false); }} underline="none">Почему так дешево</Link>
                        <Link onClick={() => { scrollToElementById("delivery"); setOpen(false); }} underline="none">Доставка и оплата</Link>
                        <Link onClick={() => { scrollToElementById("reviews"); setOpen(false); }} underline="none">Отзывы</Link>
                    </Stack>
                </Box>
            </Drawer>
        </>
    )
}