import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CssVarsProvider, extendTheme, getInitColorSchemeScript } from '@mui/joy/styles';
import Main from "./components/Main";
import ThankYouPage from "./components/ThankYouPage";
import Politics from "./components/Politics";
import Agreement from "./components/Agreement";
import DogovorVozvrata from "./components/DogovorVozvrata";
import Dostavka from "./components/Dostavka";

export const theme = extendTheme({
  components: {
    JoyLink: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: 18,
          fontWeight: 500,
        },
      },
    },
    JoyBadge: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          borderColor: '#e65100',
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          color: '#000',
          padding: "0.5em 1em",
          borderRadius: '8px',
          borderColor: '#000',
          transition: 'border-color 0.3s',
          '--Input-focusedThickness': '0rem',
          '&:hover': {
            borderColor: '#000',
          },
          '&:focus': {
            outline: 'none',
          },
          '&.Mui-focused': {
            outline: 'none',
            borderColor: '#000',
          },
          '&.MuiInputBase-root': {
            '& fieldset': {
              borderColor: '#000',
            },
            '&:hover fieldset': {
              borderColor: '#000',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#000',
            },
          },
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          borderColor: '#000',
          color: '#000',
          fontSize: '16px',
          fontWeight: 600,
          padding: '8px 16px',
          borderRadius: '8px',
          borderWidth: 2,
          transition: 'background-color 0.3s ease',
          '&:hover': {
            backgroundColor: '#000',
            color: '#fff',
          },
          '&:active': {
            backgroundColor: '#000',
            color: '#fff',
          },
          '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#9e9e9e',
          },
        },
      },
    },
  },
});

function App() {
  return (<>
    {getInitColorSchemeScript({ defaultMode: 'light' })}
    <CssVarsProvider theme={theme} defaultMode="light">
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/thank_you" element={<ThankYouPage />} />
          <Route path="/politics" element={<Politics />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/dogovor_vozvrata" element={<DogovorVozvrata />} />
          <Route path="/dostavka" element={<Dostavka />} />
        </Routes>
      </Router>
    </CssVarsProvider>
  </>
  );
}

export default App;
