import { useNavigate } from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();

    return (<div class="contact-box">
        <p><strong>Акция действует на всей территории Беларуси</strong></p>
        <p><strong>Время работы:</strong> пн-вс 09:00 - 21:00</p>
        <p><strong>ООО "Цитруском"</strong> УНП 193699866</p>
        <p>220046, г. Минск, а/я 46</p>
        <p><strong>Телефон:</strong> +375 (29) 330-44-93</p>
        <p><strong>E-mail:</strong> citrustele@mail.ru</p>
        <p><a onClick={() => navigate("/politics")}>Политика конфиденциальности</a></p>
        <p><a onClick={() => navigate("/agreement")}>Пользовательское соглашение</a></p>
        <p><a onClick={() => navigate("/dogovor_vozvrata")}>Договор возврата товара и денежных средств</a></p>
        <p><a onClick={() => navigate("/dostavka")}>Договор доставки</a></p>
    </div>)
}